import * as React from "react";
import { Seo } from "../components/seo.js";
import Layout from "../components/layout.js";

export const Head = () => (
  <Seo title="Print work | Studio 36" description="TEST TEST TEST" />
);

const PrintPage = () => {
  const divStyle = (src) => ({
    backgroundImage: "url(" + src + ")",
  });

  return (
    <Layout>
      <div
        className="page-title-section"
        style={divStyle(require(`../images/print/title-image.webp`).default)}
      >
        <span className="page-title-overlay"></span>
        <div className="container">
          <h1>Print design</h1>
          <h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
            ornare pharetra pulvinar.
          </h2>
        </div>
      </div>
    </Layout>
  );
};

export default PrintPage;
